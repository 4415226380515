import TypeTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/type/TypeTemplate';

import {
  SPOT_SHAPE,
  SPOT_OCCURRENCE,
  SPOT_OCCURRENCE_VALUES_COMMON
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinPigmentation/Type/TypeTemplate',
  component: TypeTemplate
};

const createStory = props => () => ({
  components: { TypeTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><type-template v-bind="$options.storyProps" /></div>'
});

const SPOT_OCCURRENCE_VALUES_OLDER_THAN_40 = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AT_MIDDLE_AGE
];

export const OlderThan40 = createStory({
  spotShapes: SPOT_SHAPE.IRREGULAR_AND_LARGE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.AFTER_AN_INJURY],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_OLDER_THAN_40
});

const SPOT_OCCURRENCE_VALUES_PREGNANCY = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.DURING_PREGNANCY
];

export const OlderThan20AndFemale = createStory({
  spotShapes: SPOT_SHAPE.IRREGULAR_AND_LARGE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.WHEN_A_CHILD],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_PREGNANCY
});

const SPOT_OCCURRENCE_VALUES_HRT = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AFTER_STARTING_HRT
];

export const TakingHRT = createStory({
  spotShapes: SPOT_SHAPE.IRREGULAR_AND_LARGE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.WHEN_A_CHILD, SPOT_OCCURRENCE.AFTER_STARTING_HRT],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_HRT
});

const SPOT_OCCURRENCE_VALUES_BIRTH_CONTROL = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL
];

export const TakingBirthControlPills = createStory({
  spotShapes: SPOT_SHAPE.IRREGULAR_AND_LARGE,
  firstSpotsOccurrence: [
    SPOT_OCCURRENCE.WHEN_A_CHILD,
    SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL
  ],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_BIRTH_CONTROL
});

export const NotSure = createStory({
  spotShapes: SPOT_SHAPE.ROUND_AND_SMALL,
  firstSpotsOccurrence: ['unsure'],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_COMMON
});
